export default {
  container: {
    alignItems: 'space-between',
  },
  logo: {
    margin: 'auto',
    marginTop: 30,
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    justifyContent: 'center',
    color: '#00BAA4',
    // fontFamily: 'Gotham Rounded',
    margin: 'auto',
    paddingBottom: 50,
  },
  title: {
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '4%',
  },
  normalText: {
    fontSize: 20,
    textAlign: 'center',
  },
  firstText: {
    maxWidth: 400,
    margin: 'auto',
    padding: 0,
  },
  secondText: {
    maxWidth: 500,
    padding: 0,
    margin: 'auto',
    marginBottom: '1%',
  },
  thirdText: {
    maxWidth: 400,
    padding: 0,
    margin: 'auto',
  },
  fourthText: {
    maxWidth: 400,
    padding: 0,
    margin: 'auto',
    marginBottom: '5%',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#00BAA4',
    borderColor: '#00BAA4',
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 45,
    width: 150,
  },
  form: {
    borderWidth: 1,
    borderColor: '#00BAA4',
    borderRadius: 8,
  },
  numberContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#00BAA4',
    borderRadius: 8,
    borderStyle: 'solid',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 1,
    borderRightWidth: 0,
    height: 45,
    marginLeft: 0,
    marginRight: -3,
    padding: 0,
  },
  number: { marginLeft: 4, marginTop: 8, padding: 0 },
  noMarginNoPadding: {
    margin: 0,
    padding: 0,
  },
  input: {
    color: '#00BAA4',
    borderColor: '#00BAA4',
    borderRadius: 0,
    borderLeftWidth: 0,
    height: 45,
  },
  separator: {
    margin: 0,
    height: 20,
    backgroundColor: '#00BAA4',
    marginTop: 12,
    marginLeft: 2,
    width: 1,
  },
  message: {
    maxWidth: 500,
    padding: 0,
    margin: 'auto',
    marginBottom: '1%',
    color: '#58B7A5',
  },
};
