// React
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { load } from 'recaptcha-v3';

// Styles
import { Container, Form, Row, Col, Image, Button } from 'react-bootstrap';

// Assets
import { Tenpo, Landing } from '../assets';
import styles from './styles';

const comparaOnlineSchema = Yup.object().shape({
  number: Yup.string()
    .matches(/^[0-9]*$/, 'El número que has ingresado es inválido. Inténtalo de nuevo.')
    .min(8, 'debes ingresar 8 caracteres')
    .max(8, 'debes ingresar 8 caracteres')
    .required('Ingresa tu número'),
});

class Home extends React.PureComponent {
  state = {
    message: '',
  };

  sendWsp = async (number) => {
    const recaptcha = await load('6Lel7-sUAAAAAGcyoI-fS7zT8Mkp-cKwPAwjL59O');
    const token = await recaptcha.execute('send_sms');
    const requestData = {
      phone: number,
      origin: 'descarga-tenpo-cl',
      token,
    };

    await axios
      .post('https://us-central1-tenpo-cl.cloudfunctions.net/sms', requestData)
      .then(() => {
        this.setState({
          message: '¡Hemos enviado exitosamente el enlace a tu celular!',
        });
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;
        const { status } = data;
        if (status === 'Already sent') {
          this.setState({
            message: 'El número ingresado ya se encuentra registrado',
          });
        } else {
          this.setState({
            message: 'Ha excedido el tiempo límite',
          });
        }
      });
  };

  render() {
    const handleSubmitForm = async (event) => {
      const form = event;
      const { number } = form;

      await this.sendWsp(number);
    };
    const { message } = this.state;
    return (
      <Container style={styles.container} fluid="true" className="justify-content-xs-center">
        <Row>
          <Col style={styles.logo} xs={5} md={11}>
            <Image src={Tenpo} width={150} />
          </Col>
          <Col style={styles.imageContainer} xs={12} md={6}>
            <Image src={Landing} width={500} fluid="true" />
          </Col>
          <Col style={styles.textContainer} xs={12} md={6}>
            <h3 style={styles.title}>¡Gracias por interesarte en Tenpo!</h3>
            <Container style={styles.normalText}>
              <p style={styles.firstText}>
                Crear tu cuenta digital es <span style={styles.bold}>GRATIS</span>,
              </p>
              <p style={styles.secondText}>sin comisiones, ni costos de mantención.</p>
              <p style={styles.thirdText}>Ingresa tu número y te enviaremos un enlace</p>
              <p style={styles.fourthText}>especial para descargar Tenpo en tu celular.</p>
            </Container>
            <p style={styles.normalText}>
              <span style={styles.bold}>¿Estás listo? ¡Vamos!</span>
            </p>
            <Formik
              validationSchema={comparaOnlineSchema}
              onSubmit={handleSubmitForm}
              initialValues={{
                number: '',
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form xs={12} md={12} onSubmit={handleSubmit}>
                  <Form.Row style={styles.form}>
                    <Col xs={1} md={2} />
                    <Col xs={2} md={1} style={styles.numberContainer}>
                      <Form.Label style={styles.number}>+569</Form.Label>
                    </Col>
                    <Row style={styles.separator} />
                    <Col xs={4} md={4} style={styles.noMarginNoPadding}>
                      <Form.Control
                        type="text"
                        placeholder="Tu número"
                        name="number"
                        value={values.number}
                        onChange={handleChange}
                        onFocus={() =>
                          this.setState({
                            message: '',
                          })
                        }
                        style={styles.input}
                      />
                    </Col>
                    <Col xs={3} md={3} style={styles.noMarginNoPadding}>
                      <Button type="submit" style={styles.button}>
                        Descargar
                      </Button>
                    </Col>
                    <Col xs={2} md={2} />
                    <p style={styles.message}>{errors.number ? errors.number : message}</p>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
